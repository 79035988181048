<template>
<div class="container">
      <div class="row">
        <div class="col-md-12">
123
        </div>
      </div>
    </div>
</template>

<script>
// import { mapGetters } from 'vuex'

export default {
  middleware: 'guest',
  metaInfo () {
    return { title: this.$t('home') }
  },

  data: () => ({
    title: window.config.appName
  })

  /*
  computed: mapGetters({
    authenticated: 'auth/check'
  })
  */
}
</script>
